body {
  margin: 0px;
  padding: 0px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {

  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  margin: 0px;

}


.inner_root {

  position: fixed;
  top: 5px;
  left: 5px;
  right: 5px;
  bottom: 5px;
  background-color: lightblue; /* Isso é apenas para visualização */

}
